// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import MainLayout from "./layouts/MainLayout";
// import Login from "./pages/Login";
// import NotFound from "./pages/NotFound";
// import ProtectedRoutes from "./middleware/ProtectedRoutes";
// import AddAccount from "./pages/AddAccount";
// import AddAccountGestionale from "./pages/AddAccountGestionale";
// import Confirm from "./pages/Confirm";
// import ConfirmPass from "./pages/ConfirmPass";
// import Home from "./pages/Home";
// import AddCourse from "./pages/AddCourse";
// import AddVideo from "./pages/AddVideo";
// import Gestionale from "./pages/Gestionale";
// import GestionaleCorsi from "./pages/GestionaleCorsi";
// import GestionaleVideo from "./pages/GestionaleVideo";
// import Me from "./pages/Me";
// import PassDimenticata from "./pages/PassDimenticata";
// import GestionaleAccountID from "./pages/GestionaleAccountID";
// import GestionaleCorsiID from "./pages/GestionaleCorsiID";
// import GestionaleVideoID from "./pages/GestionaleVideoID";
// import Sidebar from "./components/sidebar/Sidebar";
// import NodeView from "./components/nodeView/NodeView";
// import Carrello from "./pages/Carrello";
// import NodeLayout from "./layouts/NodeLayout";
// import ViewVideo from "./pages/ViewVideo";
// import Prove from "./pages/prove";
// import Prove2 from "./pages/prove2";

// function App() {
//   return (
//     <Router>
//       <MainLayout>
//         <Routes>
//           <Route path="/" exact element={<Login />} />
//           <Route path="/addAccount" element={<AddAccount />} />
//           <Route path="/PassDimenticata" element={<PassDimenticata />} />
//           <Route path="/confirm/:token" element={<Confirm />} />
//           <Route path="/changePass/:token" element={<ConfirmPass />} />

//           <Route element={<ProtectedRoutes />}>
//             <Route element={<NodeLayout />}>
//               <Route path="/home" element={<Home />} />
//               <Route path="/view/:id" element={<NodeView />} />
//             </Route>

//             <Route
//               path="/addAccountGestionale"
//               element={<AddAccountGestionale />}
//             />

//             <Route path="/addCourse" element={<AddCourse />} />
//             <Route path="/addVideo" element={<AddVideo />} />
//             <Route path="/gestionale" element={<Gestionale />} />
//             <Route path="/gestionaleCorsi" element={<GestionaleCorsi />} />
//             <Route path="/gestionaleVideo" element={<GestionaleVideo />} />
//             <Route path="/carrello" element={<Carrello />} />
//             <Route path="/videoId/:id" element={<ViewVideo />} />
//             {/* <Route path="/prove" element={<Prove />} />
//             <Route path="/prove2" element={<Prove2 />} /> */}

//             <Route path="/dettagliAccount/:id" element={<Me />} />

//             <Route
//               path="/gestionaleAccount/:id"
//               element={<GestionaleAccountID />}
//             />
//             <Route
//               path="/gestionaleCorsoID/:id"
//               element={<GestionaleCorsiID />}
//             />
//             <Route
//               path="/gestionaleVideoID/:id"
//               element={<GestionaleVideoID />}
//             />
//           </Route>

//           <Route path="*" element={<NotFound />} />
//         </Routes>
//       </MainLayout>
//     </Router>
//   );
// }

// export default App;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import ProtectedRoutes from "./middleware/ProtectedRoutes";
import AddAccount from "./pages/AddAccount";
import AddAccountGestionale from "./pages/AddAccountGestionale";
import Confirm from "./pages/Confirm";
import ConfirmPass from "./pages/ConfirmPass";
import Home from "./pages/Home";
import AddCourse from "./pages/AddCourse";
import AddVideo from "./pages/AddVideo";
import Gestionale from "./pages/Gestionale";
import GestionaleCorsi from "./pages/GestionaleCorsi";
import GestionaleVideo from "./pages/GestionaleVideo";
import Me from "./pages/Me";
import PassDimenticata from "./pages/PassDimenticata";
import GestionaleAccountID from "./pages/GestionaleAccountID";
import GestionaleCorsiID from "./pages/GestionaleCorsiID";
import GestionaleVideoID from "./pages/GestionaleVideoID";
import Sidebar from "./components/sidebar/Sidebar";
import NodeView from "./components/nodeView/NodeView";
import Carrello from "./pages/Carrello";
import NodeLayout from "./layouts/NodeLayout";
import ViewVideo from "./pages/ViewVideo";
import ViewVideoWebinar from "./pages/ViewVideoWebinar";
import Prove from "./pages/prove";
import Prove2 from "./pages/prove2";
import Prove3 from "./pages/prove3";
import AddWebinar from "./pages/AddWebinar";
import GestionaleWebinar from "./pages/GestionaleWebinar";
import GestionaleWebinarID from "./pages/GestionaleWebinarID";
import Corsi from "./pages/Corsi";
import NodeLayoutWeb from "./layouts/NodeLayoutWeb";
import Webinar from "./pages/Webinar";
import NodeViewWeb from "./components/nodeViewWeb/NodeViewWeb";
import CorsiNoLog from "./pages/CorsiNoLog";
import CorsoNoLogID from "./pages/CorsoNoLogID";
import WebinarNoLog from "./pages/WebinarNoLog";
import WebinarNoLogID from "./pages/WebinarNoLogID";
import WorkInProgress from "./pages/WorkInProgress";
import WorkInProgressCorsi from "./pages/WorkInProgressCorsi";
import WorkInProgressSoftware from "./pages/WorkInProgressSoftware";
import WorkInProgressWebinar from "./pages/WorkInProgressWebinar";
import AziendaInfo from "./pages/AziendaInfo";

function App() {
  return (
    <Router>
      <MainLayout>
        <Routes>
          {/* Rotte non protette ---------------- */}
          <Route path="/" exact element={<Login />} />
          <Route path="/addAccount" element={<AddAccount />} />
          <Route path="/PassDimenticata" element={<PassDimenticata />} />
          <Route path="/confirm/:token" element={<Confirm />} />
          <Route path="/changePass/:token" element={<ConfirmPass />} />

          {/* corsi */}
          <Route path="/corsiList" element={<CorsiNoLog />} />
          <Route path="/dettagliCorso/:id" element={<CorsoNoLogID />} />

          {/* webinar */}
          <Route path="/webinarList" element={<WebinarNoLog />} />
          <Route path="/dettagliWebinar/:id" element={<WebinarNoLogID />} />

          {/* work In progress */}
          <Route path="/workInProgress" element={<WorkInProgress />} />
          <Route
            path="/workInProgressCorsi"
            element={<WorkInProgressCorsi />}
          />
          <Route
            path="/workInProgressSoftware"
            element={<WorkInProgressSoftware />}
          />
          <Route
            path="/workInProgressWebinar"
            element={<WorkInProgressWebinar />}
          />
          {/* ------------------------------- */}

          {/* Rotte protette ---------------- */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/home" element={<Home />} />
            <Route path="/aziendaInfo/:id" element={<AziendaInfo />} />
            <Route path="/gestionale" element={<Gestionale />} />
            <Route
              path="/addAccountGestionale"
              element={<AddAccountGestionale />}
            />
            <Route path="/dettagliAccount/:id" element={<Me />} />
            <Route
              path="/gestionaleAccount/:id"
              element={<GestionaleAccountID />}
            />

            {/* corsi */}
            <Route path="/addCourse" element={<AddCourse />} />
            <Route path="/gestionaleCorsi" element={<GestionaleCorsi />} />
            <Route
              path="/gestionaleCorsoID/:id"
              element={<GestionaleCorsiID />}
            />
            {/* <Route name="main" element={<NodeLayout />}>
              <Route name="corso" path="corsi/:id" element={<NodeView />} />
            </Route>
            <Route name="corsi" path="/corsi" element={<Corsi />} />
          */}

            {/* webinar */}
            <Route name="mainWeb" element={<NodeLayoutWeb />}>
              <Route
                name="webinar"
                path="webinar/:id"
                element={<NodeViewWeb />}
              />
            </Route>
            <Route name="webinars" path="webinar" element={<Webinar />} />
            <Route path="/addWebinar" element={<AddWebinar />} />
            <Route path="/gestionaleWebinar" element={<GestionaleWebinar />} />
            <Route
              path="/gestionaleWebinarID/:id"
              element={<GestionaleWebinarID />}
            />

            {/* video */}
            <Route path="/addVideo" element={<AddVideo />} />
            <Route path="/videoId/:id" element={<ViewVideo />} />
            <Route path="/videoWebinarId/:id" element={<ViewVideoWebinar />} />
            <Route path="/gestionaleVideo" element={<GestionaleVideo />} />
            <Route
              path="/gestionaleVideoID/:id"
              element={<GestionaleVideoID />}
            />

            {/* carrello */}
            {/* <Route path="/carrello" element={<Carrello />} /> */}

            {/* 
            <Route path="/prove" element={<Prove />} />
            <Route path="/prove2" element={<Prove2 />} /> 
            <Route path="/prove3" element={<Prove3 />} /> 
            */}
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default App;
