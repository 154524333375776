// import React, { useEffect, useState } from "react";
// import AxiosClient from "../client/client";
// import { Link } from "react-router-dom";
// import { CaretDownFill, CaretRightFill, Trash3 } from "react-bootstrap-icons";
// import AlertMessage from "../components/alertMessage/AlertMessage";
// import { PuffLoader } from "react-spinners";

// const client = new AxiosClient();

// const Gestionale = () => {
//   const [successMessage, setSuccessMessage] = useState(null);
//   const [failedMessage, setFailedMessage] = useState(null);

//   const [accounts, setAccounts] = useState([]);

//   const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
//   const [isLoadingDelete, setIsLoadingDelete] = useState(false);

//   const [showAccounts, setShowAccounts] = useState(false);

//   const [accountsIcon, setAccountsIcon] = useState(
//     <CaretRightFill color="black" size={35} role="button" />
//   );

//   const toggleAccounts = () => {
//     setShowAccounts(!showAccounts);
//     setAccountsIcon(
//       showAccounts ? (
//         <CaretRightFill color="black" size={35} role="button" />
//       ) : (
//         <CaretDownFill color="black" size={35} role="button" />
//       )
//     );
//   };

//   // GET ACCOUNT
//   const getAccounts = async () => {
//     try {
//       setIsLoadingAccounts(true);
//       const response = await client.get("/accounts");
//       setAccounts(response);
//       setIsLoadingAccounts(false);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     getAccounts();
//   }, []);

//   const deleteAccount = async (idAccount) => {
//     const confirmDelete = window.confirm(
//       "Sei sicuro di voler eliminare questo account?"
//     );

//     if (confirmDelete) {
//       setIsLoadingDelete(true);

//       setTimeout(async () => {
//         try {
//           const response = await client.delete(`account/delete/${idAccount}`);

//           if (response.statusCode === 200) {
//             console.log("Eliminazione account avvenuta con successo");
//             setIsLoadingDelete(false);
//             setSuccessMessage("Account eliminato con successo!");
//             getAccounts();
//             setTimeout(() => {
//               setSuccessMessage(null);
//             }, 3000);
//           } else {
//             console.error(
//               "Errore durante l'eliminazione dell'account",
//               response
//             );
//             setIsLoadingDelete(false);
//             setFailedMessage("Errore durante l'eliminazione dell'account!");
//             setTimeout(() => {
//               setFailedMessage(null);
//             }, 3000);
//           }
//         } catch (error) {
//           console.log(error);
//           setIsLoadingDelete(false);
//           setFailedMessage("Errore nella richiesta al server");
//           setTimeout(() => {
//             setFailedMessage(null);
//           }, 3000);
//         }
//       }, 2000);
//     }
//   };

//   const handleDeleteClick = (accountId) => {
//     return () => {
//       deleteAccount(accountId);
//     };
//   };

//   return (
//     <>
//       {successMessage && (
//         <div>
//           <AlertMessage message={successMessage} success={true} />
//         </div>
//       )}

//       {failedMessage && (
//         <div>
//           <AlertMessage message={failedMessage} success={false} />
//         </div>
//       )}

//       {isLoadingDelete && (
//         <div className="alert-container">
//           <PuffLoader color="#ec800f" />
//         </div>
//       )}

//       <div className="pGestionale-main">
//         {/* LISTA ACCOUNT */}
//         <div className="container mb-5 gestionale-bg">
//           <div className="d-flex align-items-center">
//             <h2 className="me-3 my-0">ACCOUNT</h2>
//             <div onClick={toggleAccounts}>{accountsIcon}</div>
//           </div>

//           {showAccounts && isLoadingAccounts ? (
//             <div className="container d-flex justify-content-center spinner-margin">
//               <PuffLoader color="#ec800f" />
//             </div>
//           ) : (
//             <>
//               {showAccounts &&
//                 accounts &&
//                 accounts.accounts?.map((account) => {
//                   return (
//                     <div
//                       key={account._id}
//                       className="d-flex justify-content-between align-items-center my-4 p-account-box-singlePost"
//                     >
//                       <Link
//                         to={`/gestionaleAccount/${account._id}`}
//                         className="clear_link_black"
//                       >
//                         <div className="d-flex">
//                           <div>
//                             <img
//                               className="img-postmap me-3"
//                               src={`${account.avatar}`}
//                               alt=""
//                             />
//                           </div>
//                           <h2>{account.email}</h2>
//                         </div>
//                       </Link>
//                       {(account.role === "user" ||
//                         account.role === "azienda") && (
//                         <Trash3
//                           onClick={handleDeleteClick(account._id)}
//                           color="red"
//                           size={25}
//                           role="button"
//                           className="mx-3"
//                         />
//                       )}
//                     </div>
//                   );
//                 })}
//             </>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Gestionale;

import React, { useEffect, useState } from "react";
import AxiosClient from "../client/client";
import { Link } from "react-router-dom";
import { CaretDownFill, CaretRightFill, Trash3 } from "react-bootstrap-icons";
import AlertMessage from "../components/alertMessage/AlertMessage";
import { PuffLoader } from "react-spinners";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { Form } from "react-bootstrap";

const client = new AxiosClient();

const Gestionale = () => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);

  const [accounts, setAccounts] = useState([]);
  const [aziende, setAziende] = useState([]);

  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPageAziende, setCurrentPageAziende] = useState(1);

  const [searchTermAziende, setSearchTermAziende] = useState("");

  const [category, setCategory] = useState("tutti");
  const [isTutti, setIsTutti] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isAzienda, setIsAzienda] = useState(false);

  const [showListCashback, setShowListCashback] = useState(false);
  const [listCashbackIcon, setListCashbackIcon] = useState(
    <CaretRightFill color="black" size={35} role="button" />
  );

  const toggleListCashback = () => {
    setShowListCashback(!showListCashback);
    setListCashbackIcon(
      showListCashback ? (
        <CaretRightFill color="black" size={35} role="button" />
      ) : (
        <CaretDownFill color="black" size={35} role="button" />
      )
    );
  };

  const handleTuttiChange = () => {
    setIsTutti(!isTutti);
    if (!isTutti) {
      setIsAdmin(false);
      setIsUser(false);
      setIsAzienda(false);
      setCategory("tutti");
    } else {
      setCategory("tutti");
    }
  };

  const handleAdminChange = () => {
    setIsAdmin(!isAdmin);
    if (!isAdmin) {
      setIsTutti(false);
      setIsUser(false);
      setIsAzienda(false);
      setCategory("admin");
    } else {
      setCategory("tutti");
    }
  };

  const handleUserChange = () => {
    setIsUser(!isUser);
    if (!isUser) {
      setIsTutti(false);
      setIsAdmin(false);
      setIsAzienda(false);
      setCategory("user");
    } else {
      setCategory("tutti");
    }
  };

  const handleAziendaChange = () => {
    setIsAzienda(!isAzienda);
    if (!isAzienda) {
      setIsTutti(false);
      setIsAdmin(false);
      setIsUser(false);
      setCategory("azienda");
    } else {
      setCategory("tutti");
    }
  };

  // GET ACCOUNT
  const getAccounts = async () => {
    try {
      setIsLoadingAccounts(true);
      const response = await client.get(
        `/accounts/${category}/byEmail?email=${searchTerm}&page=${currentPage}`
      );
      setAccounts(response);
      setIsLoadingAccounts(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const handleSearchTermChange = (newTerm) => {
    setSearchTerm(newTerm);
    setCurrentPage(1); // Imposta currentPage a 1 quando il termine di ricerca cambia
  };

  useEffect(() => {
    getAccounts();
  }, [currentPage, searchTerm, category]);

  //console.log(accounts.accounts);

  // GET AZIENDE
  const getAziende = async () => {
    try {
      //setIsLoadingAccounts(true);
      const response = await client.get(
        `/aziendeAccounts/byNome?nome=${searchTermAziende}&page=${currentPageAziende}`
      );
      setAziende(response);
      //setIsLoadingAccounts(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaginationAziende = (value) => {
    setCurrentPageAziende(value);
  };

  const handleSearchTermChangeAziende = (newTerm) => {
    setSearchTermAziende(newTerm);
    setCurrentPageAziende(1); // Imposta currentPage a 1 quando il termine di ricerca cambia
  };

  useEffect(() => {
    getAziende();
  }, [currentPageAziende, searchTermAziende]);
  console.log(aziende);

  const deleteAccount = async (idAccount) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler eliminare questo account?"
    );

    if (confirmDelete) {
      setIsLoadingDelete(true);

      setTimeout(async () => {
        try {
          const response = await client.delete(`account/delete/${idAccount}`);

          if (response.statusCode === 200) {
            console.log("Eliminazione account avvenuta con successo");
            setIsLoadingDelete(false);
            setSuccessMessage("Account eliminato con successo!");
            getAccounts();
            setTimeout(() => {
              setSuccessMessage(null);
            }, 3000);
          } else {
            console.error(
              "Errore durante l'eliminazione dell'account",
              response
            );
            setIsLoadingDelete(false);
            setFailedMessage("Errore durante l'eliminazione dell'account!");
            setTimeout(() => {
              setFailedMessage(null);
            }, 3000);
          }
        } catch (error) {
          console.log(error);
          setIsLoadingDelete(false);
          setFailedMessage("Errore nella richiesta al server");
          setTimeout(() => {
            setFailedMessage(null);
          }, 3000);
        }
      }, 2000);
    }
  };

  const handleDeleteClick = (accountId) => {
    return () => {
      deleteAccount(accountId);
    };
  };

  return (
    <>
      {successMessage && (
        <div>
          <AlertMessage message={successMessage} success={true} />
        </div>
      )}

      {failedMessage && (
        <div>
          <AlertMessage message={failedMessage} success={false} />
        </div>
      )}

      {isLoadingDelete && (
        <div className="alert-container">
          <PuffLoader color="#ec800f" size={200} />
        </div>
      )}

      <div className="margin_T150">
        {/* LISTA ACCOUNT */}
        <div className="container mb-5 gestionale-bg">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h2 className="me-3 my-0">ACCOUNT</h2>

              <Form.Group controlId="checkbox-form" className="mt-3">
                <div className="d-flex ms-5">
                  <Form.Check
                    className="me-4 circle-checkbox"
                    type="checkbox"
                    label="Tutti"
                    checked={isTutti}
                    onChange={handleTuttiChange}
                  />

                  <Form.Check
                    className="me-4 circle-checkbox"
                    type="checkbox"
                    label="Admin"
                    checked={isAdmin}
                    onChange={handleAdminChange}
                  />
                  <Form.Check
                    className="me-4 circle-checkbox"
                    type="checkbox"
                    label="Azienda"
                    checked={isAzienda}
                    onChange={handleAziendaChange}
                  />

                  <Form.Check
                    className="circle-checkbox"
                    type="checkbox"
                    label="User"
                    checked={isUser}
                    onChange={handleUserChange}
                  />
                </div>
              </Form.Group>
            </div>

            <input
              className="my_input"
              type="text"
              placeholder="Cerca per email"
              value={searchTerm}
              onChange={(e) => handleSearchTermChange(e.target.value)}
            />
          </div>

          {/* {showAccounts && isLoadingAccounts ? (
            <div className="container d-flex justify-content-center spinner-margin">
              <PuffLoader color="#ec800f" />
            </div>
          ) : (
            <>
              {showAccounts &&
                accounts &&
            accounts.accounts
              ?.filter((account) =>
                account.email.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((account) => {
                    return (
                      <div
                        key={account._id}
                        className="d-flex justify-content-between align-items-center my-4 p-account-box-singlePost"
                      >
                        <Link
                          to={`/gestionaleAccount/${account._id}`}
                          className="clear_link_black"
                        >
                          <div className="d-flex">
                            <div>
                              <img
                                className="img-postmap me-3"
                                src={`${account.avatar}`}
                                alt=""
                              />
                            </div>
                            <h2>{account.email}</h2>
                          </div>
                        </Link>
                        {(account.role === "user" ||
                          account.role === "azienda") && (
                          <Trash3
                            onClick={handleDeleteClick(account._id)}
                            color="red"
                            size={25}
                            role="button"
                            className="mx-3"
                          />
                        )}
                      </div>
                    );
                  })}
            </>
          )} */}

          {accounts &&
            accounts.accounts
              ?.filter((account) =>
                account.email.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((account) => {
                return (
                  <div
                    key={account._id}
                    className="d-flex justify-content-between align-items-center my-4 p-account-box-singlePost"
                  >
                    <Link
                      to={`/gestionaleAccount/${account._id}`}
                      className="clear_link_black"
                    >
                      <div className="d-flex">
                        {/* <div>
                          <img
                            className="img-postmap me-3"
                            src={`${account.avatar}`}
                            alt=""
                          />
                        </div> */}
                        <h5 className="ms-4 mt-1">{account.email}</h5>
                      </div>
                    </Link>
                    {(account.role === "user" ||
                      account.role === "azienda") && (
                      <Trash3
                        onClick={handleDeleteClick(account._id)}
                        color="red"
                        size={25}
                        role="button"
                        className="mx-3"
                      />
                    )}
                  </div>
                );
              })}
          <ResponsivePagination
            current={currentPage}
            total={accounts && accounts.totalPages}
            onPageChange={handlePagination}
          />
        </div>
        <div className="mb-5"></div>
      </div>
      <div className="d-flex align-items-center container mb-5">
        <div onClick={toggleListCashback}>{listCashbackIcon}</div>
        {showListCashback === false && (
          <h5 className="m-0">Mostra resoconto cashback</h5>
        )}
        {showListCashback === true && (
          <h5 className="m-0">Nascondi resoconto cashback</h5>
        )}
      </div>
      {showListCashback && (
        <div className="container mt-0 mb-5 gestionale-bg">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h2 className="me-3 my-0">Classifica Cashback aziende</h2>
            </div>

            <input
              className="my_input"
              type="text"
              placeholder="Cerca per nome azienda"
              value={searchTermAziende}
              onChange={(e) => handleSearchTermChangeAziende(e.target.value)}
            />
          </div>

          {aziende &&
            aziende.accounts
              ?.filter((account) =>
                account.nome
                  .toLowerCase()
                  .includes(searchTermAziende.toLowerCase())
              )
              .map((account) => {
                return (
                  <div
                    key={account._id}
                    className="d-flex justify-content-between align-items-center my-4 p-account-box-singlePost"
                  >
                    <Link
                      to={`/gestionaleAccount/${account._id}`}
                      className="clear_link_black"
                    >
                      <div className="d-flex">
                        <div className="ms-3">
                          {/* {account.totCashback && account.totCashback > 0 && (
                          <h3 className="m-0">{account.totCashback} €</h3>
                        )} */}
                          {account.totCashback > 0 ? (
                            <h3 className="m-0">{account.totCashback} €</h3>
                          ) : (
                            <h3 className="m-0">0 €</h3>
                          )}
                        </div>
                        <h5 className="ms-4 mt-1">{account.nome}</h5>
                      </div>
                    </Link>
                    {(account.role === "user" ||
                      account.role === "azienda") && (
                      <Trash3
                        onClick={handleDeleteClick(account._id)}
                        color="red"
                        size={25}
                        role="button"
                        className="mx-3"
                      />
                    )}
                  </div>
                );
              })}
          <ResponsivePagination
            current={currentPageAziende}
            total={aziende && aziende.totalPages}
            onPageChange={handlePaginationAziende}
          />
        </div>
      )}
    </>
  );
};

export default Gestionale;
